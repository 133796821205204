<template>
  <div class="bg">
    <div class="content">
      <div class="content-view">
        <span class="content-s">广告监测平台为面向集团内部各互联网业务的一站式广告渠道效果追踪分析平台，接入简便，可有效控制与外部广告厂商之间的对接成本，平台将为各团队提供统一的、全局的广告效果分析，以支撑各互联网业务团队优化广告投放，科学提升ROI。详情可推推联系集团数据平台部，或加入推推群：广告监测支撑群。</span>
      </div>
    </div>

    <div class="footerview">
      <span class="footer-s">© 2019 - 2020 MoniData 版权所有 上海奇虎科技有限公司 | 备案号: </span>
      <img class="foot-img" src="../assets/b.png">
      <span class="footer-s"> 沪ICP备12043592号-11</span>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  components: {

  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
  background: url(../assets/a.png) no-repeat center center / cover;
  /* display: grid; */
  /* grid-template-rows: auto 1fr auto; */
  height: 100vh;
}

.content {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-view {
  height: 200px;
  margin: auto 50px;
}

.content-s {
  color: white;
  text-shadow: 6px 7px 5px #000000;
}

.footer-s {
  color: white;
}

.footerview {
  height: 10vh;
}

.foot-img {
  padding-top: 5px;
}

</style>
